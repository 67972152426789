import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as BGU from 'three/examples/jsm/utils/BufferGeometryUtils.js';


//import images//
import ns from '../images/work_images/ns_small.jpg';
import ntol from '../images/work_images/ntol_small.jpg';
import mml from '../images/work_images/mml_inside_small.jpg';
import rd from '../images/work_images/remaining_distort_small.jpg';
import embodied from '../images/work_images/embodied_small.jpg';
import wod from '../images/work_images/wod_small.jpg';
import psa from '../images/work_images/psa_small.jpg';
import soma_ai from '../images/work_images/soma_ai_small.jpg';
import extraphysical from '../images/work_images/extra-physical_small.jpg';






// BASIC THREE VARIABLES ///
const loadManager = new THREE.LoadingManager();
const windowHalf = new THREE.Vector2( window.innerWidth / 2, window.innerHeight / 2 );


/// ANIMATION VARIABLES ///
const target = new THREE.Vector2();
const mouse = new THREE.Vector2();
var lightTarget = new THREE.Vector3(); // create once an reuse it
var timer;
var t = 0;
var L;
var FOV = false;
var workbar_MULTIPLIER;

// LOading VARIABLES
var fill;
var load_bar
fill = document.getElementById('loadFilling');
load_bar = document.getElementById('loader');
// ----
fill.style.width = 0 + '%';


// MAKEUP ELEMENT VARIABLES
const workbar = document.getElementById('elements');
const work_list = {
'psa':[psa, "Public Space Algorithm", ["Dance, public space,", "group-work,", "space between bodies,", "intervention,", "#happy harmonising!"]],
'wod':[wod,"Wings of Desire", ["physical performance,", "duet,", "shared dreams,", "embodied imagination"]],
'embodied':[embodied, "Embodied in the ground of otherness", ["The other side,", "installation,", "standing on the shoulders of giants,", "Virtual Reality"]],
'mml':[mml, "Meta-Movement-Land", ["Audience becomes performer,", "solo performance installation,", "choreography of interactions,", "VR- & physical performance"]],
'remaining_distort':[rd,"Remaining Distortion", ["Digital shrine,", "installation,", "Eternalising choreography,",  "Embodied website"]],
'ntol':[ntol, "Nobody's Tree of Life",[ "Dance-theatre through VR,", "solo,", "Sharing physical experience"]],
'NS':[ns,"Nobody's Sanctuary", ["Personal space experience,", "immersed in the narrative,", "solo dance through VR,", "your own body"]],
'soma_ai':[soma_ai,"Soma A.I.", ["current research,", "installation", "A.I. or embodied experience?"]],
'extra-physical':[extraphysical,"Extra-physical", ["Dance & physical theatre,", "live beyond your skin,", "collective thinking,"]]}
const liminalbar = document.getElementById("liminal1");


// interaction variables //
var PFFalse = false;
var CPBool = false;
const mailHover = document.getElementById("mail_hover");
const contactMenu = document.getElementById('contact_menu');

const workellist = [];

// NEW VERSION PROOF
console.log("<3<3<3");

// HTML make-up ELEMENTS //
for (let [key, value] of Object.entries(work_list))
{
    const div = document.createElement('div');
    const img = document.createElement('img');
    const p = document.createElement('p');
    const p_cont = document.createElement('div')
    const text = document.createElement('div');
    const title = document.createElement('div')
    const info = document.createElement('div');

    p.innerText = '>';
    p_cont.classList.add('name_cont');
    p.classList.add('work_name');
    img.src = value[0]
    p_cont.append(p);
    div.append(p_cont);
    div.append(img);

    div.classList.add('workElement');
    text.classList.add('PF_Text')
    title.innerText = value[1];


    for (var e of value[2]){
        const p_el = document.createElement('p');
        console.log(e);
        p_el.innerHTML = e;
        info.append(p_el)
    }

    info.classList.add("info");

    text.append(title)
    text.append(info);
    workellist.push(div);
    div.id = key;
    workbar.append(div);
    workbar.append(text);
};



//THREE JS ------ BASICS//
const renderer = new THREE.WebGLRenderer({ alpha: true });
renderer.setSize(window.innerWidth, window.innerHeight);
document.body.appendChild(renderer.domElement);

const scene = new THREE.Scene();
renderer.setPixelRatio(window.devicePixelRatio);
renderer.setSize(window.innerWidth, window.innerHeight);
const camera = new THREE.PerspectiveCamera(
    150,
    window.innerWidth / window.innerHeight,
    1,
    1000000
);


//THREE JS ------- WINDOW RESIZE FUNCTION //
window.addEventListener( 'resize', onWindowResize, false );

function onWindowResize(){
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize( window.innerWidth, window.innerHeight );
    windowHalf.x = window.innerWidth / 2;
    windowHalf.y = window.innerHeight / 2;
    workbar_MULTIPLIER = (workbar.scrollWidth-window.innerWidth)/window.innerWidth;
}


loadManager.onProgress = function (url, itemsLoaded, itemsTotal) {
    fill.style.width = (itemsLoaded / itemsTotal * 80) + 'vw';
    var testtime = (itemsLoaded / itemsTotal * 100);
    if (testtime = 100) {
        console.log("100 reached!");
    }
};

const PP_sphere_diffuse = new URL('../images/josse_sphere_diffuse_spheremap_1.png', import.meta.url);
const PP_sphere_normal = new URL('../images/josse_sphere_normal_spheremap_7.png', import.meta.url);

var textloader = new THREE.TextureLoader(loadManager);
var loader = new GLTFLoader(loadManager);

var sphere_Texture = textloader.load(PP_sphere_diffuse);
var sphere_Normal = textloader.load(PP_sphere_normal);


loadManager.onLoad = () => {
    var interval;
    setTimeout(function () {
        interval = setInterval(function () {
            // timer += 0.5;
            // interval += 30;
        }, 1000);
    }, 3000);
    setTimeout(function () {
        // fill.classList.add('loadingmore');
        console.log("jiha");
        clearInterval(interval);
        console.log(scene);
        console.log(load_bar);
        load_bar.classList.add('complete');
        console.log(workbar.scrollWidth);
        workbar_MULTIPLIER = (workbar.scrollWidth-window.innerWidth)/window.innerWidth;
        add_geometry(boxGeometry);
        mouseStopped();
        start_movement();
    }, 1000);
}








// SETUP INTERACTIONS //

document.getElementById('work').addEventListener('click', panelshift);
document.getElementById('work_close').addEventListener('click', panelshift);
document.getElementById("contact").addEventListener('click', contact);

function panelshift(){
    console.log('clicked');
    if(PFFalse == false)
    {
        renderer.domElement.style.marginTop = -100+'vh';
        liminalbar.classList.add('liminal_on');
        CPBool = true;
        contact();

    } else {
        renderer.domElement.style.marginTop = 0+'vh';
        liminalbar.classList.remove('liminal_on');
    }
    PFFalse = PFFalse ? false : true;
    console.log(PFFalse);
}


function contact(){
    if(CPBool == false){
        contactMenu.classList.add('visible');
    }else{
        contactMenu.classList.remove('visible');
    }
    CPBool = CPBool ? false : true;
    console.log(CPBool)
}

// hover_animation of mail copy to clipboard

const mail = document.getElementById("mail");
mail.addEventListener("mouseenter", e=>{
    // console.log("enter");
    mailHover.classList.add("hover_visible");
});
mail.addEventListener("mouseout", e=>{
    // console.log("leave");
    mailHover.classList.remove("hover_visible");
});
mail.addEventListener("click", e=>{
    navigator.clipboard.writeText("josse.vessiesz@gmail.com");
})



// setup //
camera.position.set(0, 0, 0);
scene.add(camera);
renderer.render(scene, camera);

/// lighting ///
const light = new THREE.SpotLight( 0xffffff, 55, 200, Math.PI/2.25, 1, 1);
light.position.set(0,0,0);
scene.add(light);
scene.add(light.target);


// GEOMETRY //
const boxGeometry_2 = new THREE.Mesh(
    new THREE.SphereGeometry(0.5, 12, 12),
    new THREE.MeshStandardMaterial({ visible:false, color: 0xff0000} )
);
boxGeometry_2.position.set(0, 0, -5);
camera.add(boxGeometry_2);


const boxGeometry = new THREE.Mesh(
    new THREE.SphereGeometry(6, 12, 12),
    new THREE.MeshPhongMaterial({roughness: .3, metalness: 1, color: 0xffffff, emissive: 0x000000 ,side: THREE.DoubleSide, map: sphere_Texture, normalMap: sphere_Normal, normalMapType: THREE.TangentSpaceNormalMap,  normalScale: new THREE.Vector2(2, 2)})
);

//. ADDING FUNCTION CALLED IN ONLOAD .//
function add_geometry(box){
    box.geometry.computeTangents(true);
    box.rotateY(14.3);
    box.geometry = BGU.mergeVertices(boxGeometry.geometry);
    box.geometry.computeVertexNormals(true);
    scene.add(boxGeometry);
}



// ANIMATION FUNCTIONS //
function camera_rotate(){
    target.x = (  mouse.x ) * 0.002;
    target.y = (  mouse.y ) * 0.002;
    camera.rotation.x += 0.05 * ( target.y - camera.rotation.x );
    camera.rotation.y += 0.05 * ( target.x - camera.rotation.y );
}

function start_movement(){
    document.getElementsByTagName('body')[0].addEventListener('mousemove', (e)=>{
        clearTimeout(timer);
        timer=setTimeout(mouseStopped,1000);
        FOV = false
        mouse.x = ( e.clientX - windowHalf.x );
        mouse.y = ( e.clientY - windowHalf.y );
        // console.log(100*(e.clientY/window.innerHeight), 100*(e.clientX/window.innerwidth));
        mailHover.style.top = (100*(e.clientY/window.innerHeight)-8) + "%";
        mailHover.style.left = (100*(e.clientX/window.innerWidth)+3) + "%";
        // console.log(workbar_MULTIPLIER*(- mouse.x - windowHalf.x));
        workbar.style.marginLeft = workbar_MULTIPLIER*(- mouse.x - windowHalf.x+75) + 'px';
    });
        workellist.forEach(e => {
        e.classList.add('work_hidden');
    });
};

function mouseStopped(){
        FOV = true;
}


animate();

function animate() {
    camera_rotate();
    scene.children[0].children[0].getWorldPosition( lightTarget );
    if( FOV == true){
        if(t < 0.99){
            t += 0.005
        }
    } else{
        if(t > 0.01){
            t -= 0.007
        }
    }
    L = Math.sin((Math.PI/2) * (Math.sin((t*2)-1)))
    camera.fov = (149.9*L) + (140.1 *(1-L));
    light.angle = (Math.PI/2.25)*L + (Math.PI/2.3)*(1-L);
    camera.updateProjectionMatrix();
    scene.children[2].position.set(lightTarget.x, lightTarget.y, lightTarget.z);
    requestAnimationFrame(animate);
    renderer.render(scene, camera);
}